const $fetch = {
  post: async (url = "", data = {}, headers = {}, basePath = process.env.VUE_APP_BASEPATH) => {
    // Default options are marked with *
    const response = await fetch(basePath + url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': 'sid-81fc8bd7405f9e85c97bb7def886f8cd',
        // 'Content-Type': 'application/x-www-form-urlencoded',
        ...headers,
      },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  },
  fetch: async (url = "", data = {}, headers = {}) => {
    // Default options are marked with *
    
    /** 生产环境 */ 
    const base_url = 'https://api.cumen.fun/api/'
    
    /** 开发环境 */ 
    // const base_url = '/api/'

    const response = await fetch(base_url + url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': 'sid-81fc8bd7405f9e85c97bb7def886f8cd',
        // 'Content-Type': 'application/x-www-form-urlencoded',
        ...headers,
      },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }
};

export default $fetch;
