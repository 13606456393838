<template>
  <div class="container">
    <img
      :class="['bgimg', nobody ? 'mt10' : '']"
      :src="campaign.post.images[0].image_info.thumbnail_url"
    />
    <div v-if="!nobody" class="main">
      <div class="info">
        <div class="avatar-box">
          <img
            v-for="avatar of campaign.avatars"
            :key="avatar"
            class="avatar"
            :src="avatar"
          />
        </div>
        <span class="desc">{{ campaign.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import $fetch from "@/utils/fetch.js";
export default {
  name: "details",
  data() {
    return {
      campaignId: "",
      campaign: {},
      nobody: true,
    };
  },
  created() {
    let { campaignId } = this.$route.query;
    this.getCampaign((this.campaignId = campaignId));
  },
  methods: {
    /** 获取活动详情 */
    getCampaign(campaignId) {
      Promise.all([
      $fetch.post("GetSharedCampaign", {
          postId: "",
          campaignId,
      }), $fetch.post("GetActivityParticipantsSummary", {
          campaignId
      })]).then(res => {
          const [campaign, summary] = res;
          console.log(campaign);
          this.campaign = {
            ...campaign,
            text: summary.content,
            avatars: summary.avatar_urls
          };
          this.nobody = !campaign.text;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.7857;

.container {
  @include column(flex-end, flex-start);
  position: relative;
  width: 100%;
  height: 80vw;
  background-color: #fff;

  > .bgimg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: px2rem(560);
    object-fit: cover;
  }

  > .mt10 {
    margin-top: px2rem(10 * $multipleCount);
  }

  > .main {
    width: 100%;
    height: px2rem(92 * $multipleCount);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    background-image: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01841dbe-fe2d-9f69-e330-7fa3ea6ed21e?x-oss-process=style/origin);
    z-index: 1;

    > .info {
      @include row(flex-start, center);
      position: absolute;
      bottom: px2rem(8 * $multipleCount);
      left: px2rem(8 * $multipleCount);
      width: 75%;
      height: px2rem(32 * $multipleCount);
      transform-origin: center center;
      transform: rotate(-2deg);
      overflow: hidden;

      > .avatar-box {
        @include row(flex-start, center);
        height: px2rem(28 * $multipleCount);
        > .avatar {
          display: block;
          box-sizing: border-box;
          width: px2rem(28 * $multipleCount);
          height: 100%;
          background-color: #ccc;
          border-radius: 50%;
          border: px2rem(2 * $multipleCount) solid #fff;

          &:not(:first-child) {
            margin-left: px2rem(-8 * $multipleCount);
          }
        }
      }

      > .desc {
        color: #1ffd91;
        font-size: px2rem(20 * $multipleCount);
        margin-left: px2rem(8 * $multipleCount);
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
</style>
